<template>
  <div class="wr print-page-component" style="background: #ffffff; height: 100%; float: none !important;">
    <v-toolbar color="grey lighten-4" elevation="1" class="non-printable ttolbar" style="align-items: center">
      <v-select :items="forms" v-model="document_source" label="Друкована форма" class="mt-2"
                color="grey darken-1"
                hide-details style="max-width: 600px"
                @change="onPrintFormChange"
      />
      <v-spacer/>
      <v-btn
          class="grey lighten-3 mr-2"
          icon
          @click.stop="openSettings"
      >
        <v-icon color="teal">mdi-filter-menu-outline</v-icon>
      </v-btn>
      <v-btn
          class="grey lighten-3 mr-2"
          icon
          outlined
          @click.stop="print"
      >
        <v-icon color="primary">mdi-printer</v-icon>
      </v-btn>
      <v-btn
          class="grey lighten-3 mr-2"
          icon
          outlined
      >
        <v-icon color="success">mdi-file-excel-outline</v-icon>
      </v-btn>
      <v-btn
          class="grey lighten-3 mr-2"
          icon
          outlined
          @click.stop="closeModal"
      >
        <v-icon color="primary">mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="column-wrapper" style="display: block; float: none !important;">
      <div class="wrapper-page" style="float: none !important;">
        <component :is="componentCurrent"
                   :print_data="print_data"
                   :document_id="document_id"
                   :organization="organization"
                   :global_settings="settings"
                   :modal="modal"
                   @closeSettings="closeSettings"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_ORGANIZATION_PRINT_DATA} from "@/store/actions/organization";

export default {
  name: "PrintDocument",
  components: {},
  props: ['doc_type', 'doc_id', 'doc_form', 'modal'],
  computed: {
    ...mapGetters({
      organization: 'getCurrentOrganization',
    }),
    forms() {
      return this.print_forms[this.document_type]
    },
    componentCurrent() {
      if (this.document_type === 'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME') {
        if (this.document_print_form === 'v1') {
          return () => import("@/components/documentPrintForm/act_work_performed_outcome_v1")
        }
      }
      if (this.document_type === 'DOCUMENT_ACT_WORK_PERFORMED_INCOME') {
        if (this.document_print_form === 'v1') {
          return () => import("@/components/documentPrintForm/act_work_performed_income_v1")
        }
      }
      if (this.document_type === 'DOCUMENT_INVOICE_WAREHOUSE_OUTCOME') {
        if (this.document_print_form === 'v1') {
          return () => import("@/components/documentPrintForm/invoice_warehouse_outcome_v1")
        }
      }
      if (this.document_type === 'DOCUMENT_INVOICE_WAREHOUSE_INCOME') {
        if (this.document_print_form === 'v1') {
          return () => import("@/components/documentPrintForm/invoice_warehouse_income_v1")
        }
      }
      if (this.document_type === 'DOCUMENT_BILL_OUTCOME') {
        if (this.document_print_form === 'v1') {
          return () => import("@/components/documentPrintForm/bill_outcome_v1")
        }
      }
      if (this.document_type === 'DOCUMENT_ADVANCE_REPORT') {
        if (this.document_print_form === 'v1') {
          return () => import("@/components/documentPrintForm/advance_report_v1")
        }
        if (this.document_print_form === 'v2') {
          return () => import("@/components/documentPrintForm/advance_report_v2")
        }
      }
      if (this.document_type === 'DOCUMENT_CASH_ORDER_INCOME') {
        if (this.document_print_form === 'v1') {
          return () => import("@/components/documentPrintForm/cash_order_income_v1")
        }
      }
      if (this.document_type === 'DOCUMENT_CASH_ORDER_OUTCOME') {
        if (this.document_print_form === 'v1') {
          return () => import("@/components/documentPrintForm/cash_order_outcome_v1")
        }
      }
      if (this.document_type === 'DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME') {
        if (this.document_print_form === 'v1') {
          return () => import("@/components/documentPrintForm/warehouse_inventory_outcome_v1")
        }
      }
      if (this.document_type === 'DOCUMENT_PERSON_TIMESHEET') {
        if (this.document_print_form === 'v1') {
          return () => import("@/components/documentPrintForm/person_timesheet_v1")
        }
      }
      if (this.document_type === 'GET_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS') {
        if (this.document_print_form === 'v1') {
          return () => import("@/components/documentPrintForm/tax_bill_outcome_v1")
        }
      }
      return null
    }
  },
  data() {
    return {
      settings: false,
      document_type: null,
      document_id: null,
      document_print_form: null,
      document_source: null,
      print_data: {},
      print_forms: {
        'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME': [
          {
            text: 'Акт виконаних робіт (типова форма)',
            value: 'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME_v1',
            document_type: 'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME',
            document_print_form: 'v1',
          }
        ],
        'DOCUMENT_ACT_WORK_PERFORMED_INCOME': [
          {
            text: 'Акт виконаних робіт (типова форма)',
            value: 'DOCUMENT_ACT_WORK_PERFORMED_INCOME_v1',
            document_type: 'DOCUMENT_ACT_WORK_PERFORMED_INCOME',
            document_print_form: 'v1',
          }
        ],
        'DOCUMENT_BILL_OUTCOME': [
          {
            text: 'Рахунок за надані послуги (типова форма)',
            value: 'DOCUMENT_BILL_OUTCOME_v1',
            document_type: 'DOCUMENT_BILL_OUTCOME',
            document_print_form: 'v1',
          }
        ],
        'DOCUMENT_INVOICE_WAREHOUSE_OUTCOME': [
          {
            text: 'Видаткова накладна (типова форма)',
            value: 'DOCUMENT_INVOICE_WAREHOUSE_OUTCOME_v1',
            document_type: 'DOCUMENT_INVOICE_WAREHOUSE_OUTCOME',
            document_print_form: 'v1',
          }
        ],
        'DOCUMENT_INVOICE_WAREHOUSE_INCOME': [
          {
            text: 'Прибуткова накладна (типова форма)',
            value: 'DOCUMENT_INVOICE_WAREHOUSE_INCOME_v1',
            document_type: 'DOCUMENT_INVOICE_WAREHOUSE_INCOME',
            document_print_form: 'v1',
          }
        ],
        'DOCUMENT_ADVANCE_REPORT': [
          {
            text: 'Авансовий звіт (типова форма)',
            value: 'DOCUMENT_ADVANCE_REPORT_v1',
            document_type: 'DOCUMENT_ADVANCE_REPORT',
            document_print_form: 'v1',
          },
          {
            text: 'Авансовий звіт (нова форма)',
            value: 'DOCUMENT_ADVANCE_REPORT_v2',
            document_type: 'DOCUMENT_ADVANCE_REPORT',
            document_print_form: 'v2',
          }
        ],
        'DOCUMENT_CASH_ORDER_INCOME': [
          {
            text: 'Прибутковий касовий ордер',
            value: 'DOCUMENT_CASH_ORDER_INCOME_v1',
            document_type: 'DOCUMENT_CASH_ORDER_INCOME',
            document_print_form: 'v1',
          }
        ],
        'DOCUMENT_CASH_ORDER_OUTCOME': [
          {
            text: 'Видатковий касовий ордер',
            value: 'DOCUMENT_CASH_ORDER_OUTCOME_v1',
            document_type: 'DOCUMENT_CASH_ORDER_OUTCOME',
            document_print_form: 'v1',
          }
        ],
        'DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME': [
          {
            text: 'Акт списання товарів',
            value: 'DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME_v1',
            document_type: 'DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME',
            document_print_form: 'v1',
          }
        ],
        'DOCUMENT_PERSON_TIMESHEET': [
          {
            text: 'Табель обліку робочого часу',
            value: 'DOCUMENT_PERSON_TIMESHEET_v1',
            document_type: 'DOCUMENT_PERSON_TIMESHEET',
            document_print_form: 'v1',
          }
        ],
        'GET_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS': [
          {
            text: 'Податкова накладна (вихідна)',
            value: 'GET_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS_v1',
            document_type: 'GET_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS',
            document_print_form: 'v1',
          }
        ]
      }
    }
  },
  methods: {
    openSettings() {
      this.settings = !this.settings
    },
    closeSettings() {
      this.settings = false
    },
    print() {
      window.print()
    },
    closeModal() {
      this.$emit('closeModal')
    },
    onPrintFormChange(payload) {
      if (!payload) return;

      const split = payload.split('_')
      const document_type = split.slice(0, split.length - 1).join('_')
      const document_print_form = split[split.length - 1]

      this.document_print_form = document_print_form
      this.document_type = document_type
    }
  },
  watch: {
    doc_type: {
      immediate: true,
      handler(payload) {
        this.document_type = payload
        this.document_source = `${payload}_${this.document_print_form}`
      }
    },
    doc_id: {
      immediate: true,
      handler(payload) {
        this.document_id = payload
      }
    },
    doc_form: {
      immediate: true,
      handler(payload) {
        this.document_print_form = payload
        this.document_source = `${this.document_type}_${payload}`
      }
    }
  },
  created() {
    this.$store.dispatch(GET_ORGANIZATION_PRINT_DATA)
        .then(
            data => this.print_data = data
        )
  }
}
</script>

<style scoped lang="scss">
  .ttolbar {
    .v-toolbar__content {
      align-items: center;
    }
  }
</style>